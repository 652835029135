<!-- eslint-disable ts/no-redeclare -->
<script setup lang="ts">
import type { FormError, FormErrorEvent, FormSubmitEvent } from '#ui/types'
import { addDays, format } from 'date-fns'
import { destr } from 'destr'

const props = defineProps<{
  state?: any
  columns?: any
  params?: any
  loading?: boolean
  isemit?: boolean
  noCheckbox?: boolean
  acceptCheckboxLabel?: string
  ispayment?: boolean
  successTitle?: string
  successDescription?: string
  submitLabel?: string
  submitUri?: string
  submitCenter?: boolean
  isforgotpassword?: boolean
  isresetpassword?: boolean
  isregister?: boolean
  islogin?: boolean
  isauth?: boolean
  noresetform?: boolean
  paymentMethods?: any
}>()

const emit = defineEmits(['save', 'change', 'ok'])

const { d, t, locale } = useI18n()

const localePath = useLocalePath()

const route = useRoute()

// const slug = route.params.slug ?? ''
const slug = computed(() => _get(route.params.slug ?? [], 0, ''))

const host = await useGetHost('host')

const headers: any = { domain: host, locale: locale.value, slug: slug.value }

if (props?.isauth)
  _set(headers, 'isauth', props?.isauth)

if (props?.submitUri)
  _set(headers, 'submituri', props?.submitUri)

const url = useRequestURL()

const token: any = useCookie('token')

const columns = computed(() => props?.columns?.length ? props?.columns?.filter((x: any) => _has(x, 'creatable') ? x?.creatable : true) : [])

const state = ref<any>(!_isEmpty(props?.state) ? props?.state : {})
const selected = ref([])
const selected2 = ref([])
const loading = ref(false)
const success = ref(false)
const backendErrors = ref('')

const tabColumn = computed(() => _get(columns.value.filter((x: any) => x?.tab && x?.type === 'select'), 0))

function getTabColumn() {
  return columns.value.filter((x: any) =>
    checkValue(state.value?.field_type)
      ? (!_has(x, 'field_type')
        || (
          _has(x, 'field_type')
            ? (x?.field_type === state.value?.field_type)
            : true
        ))
      : (
          tabColumn.value
            ? (!_has(x, 'tabcolumns')
              || (
                _has(x, 'tabcolumns') && x?.tabcolumns
                  ? (x?.tabcolumns.includes(_get(state.value, tabColumn.value?.name)))
                  : false
              ))
            : true
        ),
  )
}

const columns1 = ref(getTabColumn())

const sitedata: any = useSiteData()

const currency = useCookie('currency')

const cartStorageKey = getCartStorageKey({ site_type: sitedata.value?.site_type, locale: locale.value, currency: currency.value })

const cartStorage: any = import.meta.client ? destr(localStorage.getItem(cartStorageKey)) : {}

const cart: any = ref(cartStorage ?? {})

if (props?.ispayment)
  _set(state.value, 'payment_method', cart.value?.payment_method)

// watch(state.value, () => {
//   if (import.meta.client) {
//     columns1.value = getTabColumn()
//     onChange()
//   }
// })

async function onChangeState(columnname: any, columnvalue: any) {
  await _set(state.value, columnname, columnvalue)
  columns1.value = getTabColumn()
  onChange()
}

const validateColumns = computed(() => props?.columns?.length ? props?.columns?.filter((x: any) => _has(x, 'validate')) : [])

// function validate(state: any): FormError[] {
function validate(state: any): FormError[] {
  const errors: any = []

  validateColumns.value.forEach((item: any) => {
    const val = _get(state, item?.name)

    if (item?.validate.includes('required') && !val)
      errors.push({ path: item?.name, message: t('required') })

    const confirmedSourceVal = _get(state, _replace(item?.name, '_confirmation', ''))

    if (item?.validate.includes('confirmed') && (!confirmedSourceVal || (val && confirmedSourceVal && val !== confirmedSourceVal)))
      errors.push({ path: item?.name, message: t('confirmed') })

    if (item?.type === 'date' && item?.validate.includes('mincolumn') && (val <= _get(state, item?.mincolumn)))
      errors.push({ path: item?.name, message: t('mincolumn', { mincolumn: d(Date.parse(_get(state, item?.mincolumn)), 'short') }) })
  })

  return errors
}

async function onSave(payload: any) {
  loading.value = true

  if (!_isEmpty(props?.params))
    Object.assign(payload, props?.params)

  const data: any = await $fetch('/api/form', {
    method: 'post',
    headers,
    body: payload,
  })

  loading.value = false

  if (!data && props?.isauth) {
    token.value = null
    toastAdd({ title: t('login_again_to_continue'), color: 'red', timeout: 5000 })
    await navigateTo({ path: localePath('/login'), query: { redirect: url.href } })
    return false
  }

  const result = _clone(data)

  if (state.value?.payment_method)
    _set(result, 'payment_method', state.value?.payment_method)

  if (!props?.noresetform) {
    state.value = {}
    selected.value = []
    selected2.value = []
    success.value = false
  }

  if (data?.err) {
    backendErrors.value = data?.err
    return false
  }

  success.value = true

  emit('ok', result)

  return false
}

async function onSubmit(event: FormSubmitEvent<any>) {
  // Do something with data
  // console.log(event.data)
  // console.log(state.value)
  if (props?.ispayment && !event.data?.payment_method) {
    backendErrors.value = t('payment_method_not_selected')
    return false
  }

  if (props?.isemit)
    emit('save', event.data)

  else
    await onSave(event.data)

  return false
}

async function onError(event: FormErrorEvent) {
  const element = document.getElementById(event.errors[0].id)
  element?.focus()
  element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
}

async function onChange() {
  if (props?.isemit)
    emit('change', state.value)
}

async function onChangePayment(val: any) {
  _set(state.value, 'payment_method', val)
  onChange()
}

async function onScreenshotChange(payload: any, name: any) {
  _set(state.value, name ?? 'file', payload)
  onChange()
}
</script>

<template>
  <UForm
    :validate="validate"
    :state="state"
    class="mx-auto w-full border-gray-200 py-8 px-3 md:px-10"
    @change="onChange"
    @submit="onSubmit"
    @error="onError"
  >
    <slot name="title" />

    <UFormGroup
      v-for="item in columns1"
      :key="`sVvviL_${item?.name}`"
      :label="_get(item, `label_${locale}`) ? _get(item, `label_${locale}`) : item?.label"
      :name="item?.name"
      :required="!!item?.validate?.includes('required')"
      size="xl"
      :ui="{
        wrapper: 'mb-5',
        label: {
          base: 'text-lg',
        },
      }"
    >
      <UInput
        v-if="item?.type === 'email'"
        v-model="state[item?.name]"
        :type="item?.type"
        :placeholder="_get(item, `placeholder_${locale}`) ? _get(item, `placeholder_${locale}`) : item?.placeholder"
        :icon="item?.icon"
        :trailing="false"
        color="white"
        variant="outline"
        autocomplete="false"
        @change="(v: any) => onChangeState(item?.name, v)"
      />
      <UTextarea
        v-else-if="item?.type === 'text' || item?.type === 'textarea'"
        v-model="state[item?.name]"
        :placeholder="_get(item, `placeholder_${locale}`) ? _get(item, `placeholder_${locale}`) : item?.placeholder"
        color="white"
        variant="outline"
        @change="(v: any) => onChangeState(item?.name, v)"
      />
      <USelectMenu
        v-else-if="item?.type === 'select' && !item?.multiple && !item?.mapoptions"
        v-model="state[item?.name]"
        :options="_get(item, `options_${locale}`) ? _get(item, `options_${locale}`) : item?.options"
        :searchable="item?.options?.length > 10 ? true : false"
        :searchable-placeholder="t('search')"
        :placeholder="_get(item, `placeholder_${locale}`) ? _get(item, `placeholder_${locale}`) : item?.placeholder"
        variant="outline"
        :ui="{
          size: {
            lg: 'text-lg',
          },
          default: {
            size: 'lg',
          },
        }"
        :ui-menu="{
          input: 'text-lg',
          option: {
            size: 'text-lg',
          },
        }"
        @change="(v: any) => onChangeState(item?.name, v)"
      />
      <USelectMenu
        v-else-if="item?.type === 'select' && !item?.multiple && item?.mapoptions"
        v-model="state[item?.name]"
        :options="_get(item, `options_${locale}`) ? _get(item, `options_${locale}`) : item?.options"
        :searchable="item?.options?.length > 10 ? true : false"
        :searchable-placeholder="t('search')"
        :placeholder="_get(item, `placeholder_${locale}`) ? _get(item, `placeholder_${locale}`) : item?.placeholder"
        value-attribute="value"
        option-attribute="label"
        variant="outline"
        :ui="{
          size: {
            lg: 'text-lg',
          },
          default: {
            size: 'lg',
          },
        }"
        :ui-menu="{
          input: 'text-lg',
          option: {
            size: 'text-lg',
          },
        }"
        @change="(v: any) => onChangeState(item?.name, v)"
      >
        <template #label>
          {{ _get(item, `options_${locale}`) ? (_get(item, `options_${locale}`)?.find((v: any) => v.value === state[item?.name])?.label ?? t('select')) : item?.options?.find((v: any) => v.value === state[item?.name])?.label ?? t('select') }}
        </template>
      </USelectMenu>
      <USelectMenu
        v-else-if="item?.type === 'select' && item?.multiple && !item?.mapoptions"
        v-model="selected"
        :options="_get(item, `options_${locale}`) ? _get(item, `options_${locale}`) : item?.options"
        multiple
        :searchable="item?.options?.length > 10 ? true : false"
        :searchable-placeholder="t('search')"
        :placeholder="_get(item, `placeholder_${locale}`) ? _get(item, `placeholder_${locale}`) : item?.placeholder"
        variant="outline"
        :ui="{
          size: {
            lg: 'text-lg',
          },
          default: {
            size: 'lg',
          },
        }"
        :ui-menu="{
          input: 'text-lg',
          option: {
            size: 'text-lg',
          },
        }"
        @change="(val: any) => state[item?.name] = val"
      />
      <USelectMenu
        v-else-if="item?.type === 'select' && item?.multiple && item?.mapoptions"
        v-model="selected2"
        :options="_get(item, `options_${locale}`) ? _get(item, `options_${locale}`) : item?.options"
        multiple
        :searchable="item?.options?.length > 10 ? true : false"
        :searchable-placeholder="t('search')"
        :placeholder="_get(item, `placeholder_${locale}`) ? _get(item, `placeholder_${locale}`) : item?.placeholder"
        value-attribute="value"
        option-attribute="label"
        variant="outline"
        :ui="{
          size: {
            lg: 'text-lg',
          },
          default: {
            size: 'lg',
          },
        }"
        :ui-menu="{
          input: 'text-lg',
          option: {
            size: 'text-lg',
          },
        }"
        @change="(v: any) => onChangeState(item?.name, v)"
      />
      <UToggle
        v-else-if="item?.type === 'toggle'"
        v-model="state[item?.name]"
        on-icon="i-heroicons-check-20-solid"
        off-icon="i-heroicons-x-mark-20-solid"
      />
      <UInput
        v-else-if="item?.type === 'datetime'"
        v-model="state[item?.name]"
        type="datetime-local"
        :placeholder="_get(item, `placeholder_${locale}`) ? _get(item, `placeholder_${locale}`) : item?.placeholder"
        :icon="item?.icon"
        :trailing="false"
        :min="_has(item, 'min') ? item?.min : false"
        :max="_has(item, 'max') ? item?.max : false"
        color="white"
        variant="outline"
        @change="(v: any) => onChangeState(item?.name, v)"
      />
      <UInput
        v-else-if="item?.type === 'pin'"
        v-model="state[item?.name]"
        type="password"
        :placeholder="_get(item, `placeholder_${locale}`) ? _get(item, `placeholder_${locale}`) : item?.placeholder"
        :icon="item?.icon"
        :trailing="false"
        :min="_has(item, 'min') ? item?.min : false"
        :max="_has(item, 'max') ? item?.max : false"
        color="white"
        variant="outline"
        @change="(v: any) => onChangeState(item?.name, v)"
      />
      <div v-else-if="item?.type === 'screenshot'">
        <UInput
          v-model="state[item?.name]"
          size="sm"
          :padded="false"
          :trailing="false"
          :readonly="true"
          color="gray"
          variant="none"
          autocomplete="false"
          class="hidden"
          @change="(v: any) => onChangeState(item?.name, v)"
        />
        <ScreenshotUpload
          :item="item"
          @change="(e: any) => onScreenshotChange(e, item?.name)"
        />
      </div>
      <UInput
        v-else-if="item?.type === 'date'"
        v-model="state[item?.name]"
        type="date"
        :placeholder="_get(item, `placeholder_${locale}`) ? _get(item, `placeholder_${locale}`) : item?.placeholder"
        :icon="item?.icon"
        :trailing="false"
        :min="item?.mincolumn && state[item?.mincolumn] ? format(addDays(Date.parse(state[item?.mincolumn]), 1), 'yyyy-MM-dd') : (item?.minnow ? format(new Date(), 'yyyy-MM-dd') : (_has(item, 'min') ? item?.min : false))"
        :max="_has(item, 'max') ? item?.max : false"
        color="white"
        variant="outline"
        @change="(v: any) => onChangeState(item?.name, v)"
      />
      <UInput
        v-else-if="item?.type !== 'string'"
        v-model="state[item?.name]"
        :type="item?.type"
        :placeholder="_get(item, `placeholder_${locale}`) ? _get(item, `placeholder_${locale}`) : item?.placeholder"
        :icon="item?.icon"
        :trailing="false"
        :min="_has(item, 'min') ? item?.min : false"
        :max="_has(item, 'max') ? item?.max : false"
        :step="(item?.type === 'number' && _has(item, 'step')) ? item?.step : 1"
        color="white"
        variant="outline"
        @change="(v: any) => onChangeState(item?.name, v)"
      />
      <UInput
        v-else
        v-model="state[item?.name]"
        :placeholder="_get(item, `placeholder_${locale}`) ? _get(item, `placeholder_${locale}`) : item?.placeholder"
        :icon="item?.icon"
        :trailing="false"
        :min="_has(item, 'min') ? item?.min : false"
        :max="_has(item, 'max') ? item?.max : false"
        color="white"
        variant="outline"
        autocomplete="false"
        @change="(v: any) => onChangeState(item?.name, v)"
      />
    </UFormGroup>

    <!-- I accept the terms of use and privacy policy v-if="props?.noCheckbox" -->
    <div v-if="!props?.noCheckbox" class="my-10">
      <UCheckbox
        :label="props?.acceptCheckboxLabel || t('accept_terms')"
        required
        :ui="{ base: 'h-6 w-6', label: 'text-lg' }"
      />
    </div>

    <slot name="balance" />

    <PaymentMethod v-if="props?.ispayment" :payment-methods="props?.paymentMethods" @change="onChangePayment" />

    <UAlert
      v-if="success"
      :close-button="{ icon: 'i-heroicons-x-mark-20-solid', color: 'gray', variant: 'link', padded: false }"
      icon="i-heroicons-check-circle-solid"
      color="primary"
      variant="soft"
      :title="props?.successTitle || t('submitted')"
      :description="props?.successDescription ?? ''"
      :ui="{
        wrapper: 'my-10',
        title: 'text-lg mb-1',
        description: 'text-lg',
        icon: {
          base: 'h-12 w-12',
        },
      }"
      @close="success = false"
    />
    <UAlert
      v-else-if="backendErrors"
      :close-button="{ icon: 'i-heroicons-x-mark-20-solid', color: 'gray', variant: 'link', padded: false }"
      icon="i-heroicons-x-circle-solid"
      color="red"
      variant="soft"
      :title="t('fields_required')"
      :ui="{
        wrapper: 'my-10',
        title: 'text-lg mb-1',
        description: 'text-lg',
        icon: {
          base: 'h-12 w-12',
        },
      }"
      @close="backendErrors = ''"
    >
      <template #description>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="backendErrors" />
      </template>
    </UAlert>

    <div class="my-10 text-left flex items-center " :class="(props?.ispayment || props?.submitCenter) ? 'justify-center' : 'justify-between'">
      <UButton
        type="submit"
        :loading="loading || props?.loading"
        :disabled="_isEmpty(state)"
        :label="props?.submitLabel ?? t('submit')"
        color="blue"
        size="xl"
        class="rounded-xl "
        :class="props?.ispayment ? 'px-12 py-4 mt-5 text-xl' : 'px-10 py-3'"
      >
        <template #trailing>
          <UIcon name="i-heroicons-arrow-right-20-solid" />
        </template>
      </UButton>

      <div v-if="props?.isresetpassword || props?.isforgotpassword" class="my-5 mx-3">
        <ULink
          :to="localePath('/login')"
          active-class="text-blue"
          inactive-class="text-blue-500 hover:text-blue-700"
          class="font-semibold"
        >
          {{ t('login') }}
        </ULink>
      </div>

      <div v-if="props?.islogin" class="my-5 mx-3">
        <ULink
          :to="localePath('/forgot-password')"
          active-class="text-blue"
          inactive-class="text-blue-500 hover:text-blue-700"
          class="font-semibold"
        >
          {{ t('forgot_password') }}
        </ULink>
      </div>
    </div>

    <ButtonAccountLogin v-if="props?.isregister" />

    <ButtonAccountRegister v-if="props?.islogin" />
  </UForm>
</template>
